.node-board {
	transition: background-color 0.2s, transform 0.2s;
	background: rgba(0, 0, 0, 0.2);
}

.node-board.is-connecting {
	background-color: black;
}

.node-board.is-connecting .node,
.node-board.is-connecting .node * {
	pointer-events: none;
}

.node-board.is-connecting-type-output .node .node-input .label {
	color: #ffe501;
	font-weight: bold;
}
.node-board.is-connecting-type-output .node .node-input .label:hover {
	color: black;
}

.node-board.is-connecting-type-output .node .node-input .label {
	pointer-events: all;
}

.node-board.is-connecting-type-input .node .node-output .label {
	color: #ffe501;
	font-weight: bold;
}
.node-board.is-connecting-type-input .node .node-output .label:hover {
	color: black;
}

.node-board.is-connecting-type-input .node .node-output .label {
	pointer-events: all;
}

.node-board .node {
	position: absolute;
	border: 1px solid rgba(0, 0, 0, 0.2);
}

.node-board .menu-container {
	position: absolute;
	z-index: 999;
}
