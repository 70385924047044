.color {
	position: relative;
	width: 100%;
	height: 1.5em;
}

.color label {
	border: 1px solid rgba(0, 0, 0, 0.3);
	width: 1.5em;
	height: 1.5em;
	position: absolute;
	left: 0;
	top: 0;
	display: inline-block;
	border-radius: 1.7em;
	cursor: pointer;
}

.color .color-input {
	opacity: 0;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
}

.color .text-input {
	position: absolute;
	left: 2.75em;
	top: 0;
	border: none;
	font-family: monospace;
	border-radius: 0.25em;
	height: 2.2em;
	padding: 0.25em;
	box-sizing: border-box;
	vertical-align: middle;
	width: 78%;
}
