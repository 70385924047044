.menu {
	display: inline-block;
	padding: 0;
	margin: 0;
}

.menu li {
	list-style-type: none;
	box-sizing: border-box;
	cursor: pointer;
}
