.modal {
	background: rgba(0, 0, 0, 0.25);
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
}

.modal > .container {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}
