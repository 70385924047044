.text {
}

.text textarea,
.text input {
	border-radius: 0.25em;
	border: none;
	width: 100%;
	padding: 0.5em;
	box-sizing: border-box;
	font-family: monospace;
}
