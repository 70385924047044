.import-export-dialog {
	width: 50vw;
	height: 50vh;
	background: rgba(0, 0, 0, 0.7);
	color: white;
	padding: 0.5em 1em;
	border-radius: 0.25em;
}

.import-export-dialog p {
	margin: 0;
	padding: 0;
	font-weight: bold;
	margin-bottom: 0.5em;
}

.import-export-dialog textarea {
	width: 100%;
	height: calc(100% - 83px);
	padding: 0;
	font-family: monospace;
	border: none;
	border-radius: 0.25em;
	margin-bottom: 1em;
	background: #2b2b2d;
	color: #bbbbbb;
}

.import-export-dialog .close-button {
	position: absolute;
	right: 0.5em;
	top: 0.5em;
	transform: translate(0, -2px);
	background: transparent;
	border-radius: 1.5em;
	width: 1.5em;
	height: 1.5em;
	padding: 0;
	border: none;
	cursor: pointer;
	padding-bottom: 0.2em;
	color: white;
	font-size: 1em;
}

.import-export-dialog .close-button:hover {
	background: #ffe501;
	color: black;
}

.import-export-dialog .ok-button,
.import-export-dialog .import-button {
	border-radius: 0.25em;
	height: 2em;
	padding: 0.25em 1.5em;
	box-sizing: border-box;
	border: none;
	background: #ffe501;
	color: black;
	cursor: pointer;
	font-size: 1em;
	margin: 0 auto;
	display: block;
}
