.cable {
	position: absolute;
	z-index: 1;
	pointer-events: none;
}

.cable.is-hover .path {
	stroke: rgb(0, 200, 214);
}

.cable.is-hover circle {
	fill: rgb(0, 200, 214);
}

.cable.is-selected .path {
	stroke: #ff0065;
	stroke-dasharray: 3;
}

.cable.is-selected circle {
	fill: #ff0065;
}

.cable .path-bg,
.cable circle {
	cursor: pointer;
	pointer-events: all;
}

.cable.is-not-complete .path-bg,
.cable.is-not-complete circle {
	pointer-events: none;
}
