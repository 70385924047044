@keyframes error-message-pulse {
	0% {
		transform: translate(0, 0);
	}
	40% {
		transform: translate(0, -4px);
	}
	80% {
		transform: translate(0, 0);
	}
	90% {
		transform: translate(0, 2px);
	}
	100% {
		transform: translate(0, 0);
	}
}
.error-message {
	background: #b50000;
	color: #ffd9d9;
	padding: 1em;
	border-radius: 0.25em;
	width: 25em;
	cursor: pointer;
	font-size: 0.9em;
	animation: error-message-pulse 0.2s ease-in-out 1;
}

.error-message:hover {
	background: #520000;
}

.error-message span {
	display: block;
}
