.range {
	position: relative;
	width: 100%;
	margin-bottom: 0.1em;
}

.range > .slider {
	width: calc(100% - 7em);
	margin-top: 0.5em;
}

.range > .input {
	position: absolute;
	right: 0;
	top: 0;
	width: 6em;
	margin: 0;
	box-sizing: border-box;
	border-radius: 0.25em;
	border: none;
	padding: 0.5em;
	box-sizing: border-box;
	font-family: monospace;
}
