.edit-page {
	width: 98vw;
	height: 98vh;
	position: relative;
	background: rgba(0, 0, 0, 0.7);
	background: #3a3a3a;
	color: white;
	box-sizing: border-box;
	border-radius: 0.25em;
}

.edit-page .edit-html {
	position: absolute;
	left: 1em;
	right: 1em;
	top: 1em;
	bottom: 4em;
}

.edit-page .container {
	background: rgba(0, 0, 0, 0.9);
	border-radius: 0.25em;
}

.edit-page .save-and-close-button {
	border-radius: 0.25em;
	height: 2em;
	padding: 0.25em 1.5em;
	box-sizing: border-box;
	border: none;
	background: #ffe501;
	color: black;
	cursor: pointer;
	font-size: 1em;
	margin: 0 auto;
	display: block;
	position: absolute;
	bottom: 1em;
	left: 50%;
	transform: translate(-50%, 0);
}

.edit-page .cancel-button {
	position: absolute;
	right: 0;
	top: 0;
	transform: translate(0, 0px);
	background: transparent;
	border-radius: 0.25em;
	width: 1.2em;
	height: 1.2em;
	padding: 0;
	border: none;
	cursor: pointer;
	color: white;
	font-size: 1em;
}

.edit-page .cancel-button:hover {
	background: #ffe501;
	color: black;
}
