.App {
}

.App .error-message {
	position: fixed;
	bottom: 1em;
	left: 1em;
	z-index: 999999;
}

#todo-create-node {
	position: absolute;
	left: 0;
	top: 0;
	z-index: 999;
}

.App .main-menu {
	width: 100%;
	background: #080808;
	border-bottom: 1px solid rgba(0, 0, 0, 0.8);
	color: #7b7b7b;
	padding: 0.5em 1em;
	box-sizing: border-box;
	z-index: 1;
	position: relative;
}

.App .edit-board {
	position: fixed;
	left: 0;
	top: 2.5em;
	right: 0;
	bottom: 0;
	background: url("./components/grid-bg.png");
}

.App .edit-board .connections-board,
.App .edit-board .node-board {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	/* z-index: 1; */
}
.App .edit-board .connections-board {
	z-index: 2;
}
.App .edit-board .connections-board.is-cables-muted {
	z-index: 1;
}
.App .edit-board .node-board {
	z-index: 1;
}

.App .screen {
	z-index: 9999;
}

.App .zoom-controls {
	z-index: 999;
	position: absolute;
	left: 1.2em;
	top: 3em;
	display: none; /* @TODO */
}

.App .modal {
	z-index: 999999;
}

.App > .node-search-menu {
	position: absolute;
	right: 16.7em;
	top: 4.6em;
}
