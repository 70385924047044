.main-menu {
	height: 2.5em;
	vertical-align: middle;
}

.main-menu img {
	display: inline-block;
	height: 1.3em;
	vertical-align: middle;
	margin-right: 0.5em;
	opacity: 0.6;
}

.main-menu .toolbar {
	position: absolute;
	right: 0.5em;
	top: 0;
	height: 2.5em;
}
